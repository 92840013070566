import React, { useEffect } from 'react';
import Header from './Header/Header';
import { CalendarViewModel } from '../ViewModel/viewModel';
import { CalendarActions } from '../Actions/actions';
import { useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';
import { classes, st } from './Widget.st.css';
import EmptyState from './EmptyState/EmptyState';
import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import { BottomSectionStatus } from '../ViewModel/widgetViewModel/widgetViewModel';
import settingsParams from '../settingsParams';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import SectionHeader from './SectionHeader/SectionHeader';
import TimePicker from './TimePicker/TimePicker';
import DatePicker from './DatePicker/DatePicker';
import { Spinner } from 'wix-ui-tpa/Spinner';
import { CalendarActionsProvider } from '../Hooks/useCalendarActions';
import { CalendarFlowProvider, FlowElements, useFlow } from '../Hooks/useFlow';
import BookingDetails from './BookingDetails/BookingDetails';
import { ReactComponent as Error } from 'wix-ui-tpa/dist/src/assets/icons/Error.svg';
import RescheduledBookingDetails from './RescheduledBookingDetails/RescheduledBookingDetails';
import Dialog from './Dialog/Dialog';
import Toast from './Toast/Toast';

export interface ControllerProps extends CalendarActions, CalendarViewModel {}

const WidgetWrapper: React.FC<WidgetProps<ControllerProps>> = (props) => {
  return props.widgetViewModel?.emptyStateViewModel ? (
    <EmptyState {...props.widgetViewModel?.emptyStateViewModel} />
  ) : (
    <CalendarFlowProvider value={props.widgetViewModel.focusedElement}>
      <CalendarActionsProvider value={props}>
        <Widget {...props} />
      </CalendarActionsProvider>
    </CalendarFlowProvider>
  );
};

const Widget: React.FC<WidgetProps<ControllerProps>> = (props) => {
  const settings = useSettings();
  const { isMobile, isRTL } = useEnvironment();

  useEffect(() => void props.initializeWidget(), []);

  const getWidgetLoader = () => (
    <div data-hook="widget-loader" className={classes.loader}>
      <Spinner diameter={50} isCentered={true} />
    </div>
  );

  const getWidgetContentByStatus = () => {
    switch (props.widgetViewModel.status) {
      case BottomSectionStatus.LOADING:
        return getWidgetLoader();
      case BottomSectionStatus.LOADED:
        return <BottomSectionContent {...props} />;
      default:
        return getWidgetLoader();
    }
  };

  return (
    <div
      className={st(classes.root, {
        isRTL,
        isMobile,
        textAlignment: settings.get(settingsParams.textAlignment),
        columnAlignment: settings.get(settingsParams.columnAlignment),
      })}
      dir={isRTL ? 'rtl' : 'ltr'}
      data-hook="BookingCalendar-wrapper"
    >
      <div data-hook="top-section" className={classes.topSection}>
        <div
          data-hook="top-section-content"
          className={classes.topSectionContentContainer}
        >
          {props.rescheduleDetailsViewModel && (
            <RescheduledBookingDetails
              viewModel={props.rescheduleDetailsViewModel}
            />
          )}
          <Header viewModel={props.headerViewModel!} />
        </div>
      </div>
      <div data-hook="bottom-section" className={classes.bottomSection}>
        <div
          data-hook="bottom-section-content"
          className={classes.bottomSectionContentContainer}
        >
          {getWidgetContentByStatus()}
        </div>
      </div>
      <Dialog viewModel={props.dialogViewModel!} />
      <Toast viewModel={props.toastViewModel!} />
    </div>
  );
};

const DateAndTimeValidationToast: React.FC<{ notificationText?: string }> = ({
  notificationText,
}) => {
  return notificationText ? (
    <SectionNotification
      type="error"
      className={classes.notificationStatus}
      data-hook="notification-status-error"
    >
      <SectionNotification.Icon icon={<Error />} />
      <SectionNotification.Text>{notificationText}</SectionNotification.Text>
    </SectionNotification>
  ) : null;
};

const BottomSectionContent: React.FC<ControllerProps> = ({
  widgetViewModel,
  timePickerViewModel,
  bookingDetailsViewModel,
  datePickerViewModel,
}) => {
  const bottomSectionRef = useFlow(FlowElements.BOTTOM_SECTION);
  const timePickerRef = useFlow(FlowElements.TIME_PICKER);
  const bookingDetailsRef = useFlow(FlowElements.BOOKING_DETAILS);
  const {
    dateAndTimeSectionHeaderText,
    bookingDetailsSectionHeaderText,
    notificationText,
  } = widgetViewModel;

  return (
    <div className={classes.bottomSectionContent} ref={bottomSectionRef}>
      <div data-hook="date-time-section" className={classes.dateAndTimeSection}>
        <SectionHeader title={dateAndTimeSectionHeaderText} />
        <DateAndTimeValidationToast notificationText={notificationText} />
        <div className={classes.dateAndTimeSectionBody}>
          <div className={classes.dateSubSection}>
            <DatePicker datePickerViewModel={datePickerViewModel!} />
          </div>
          <div ref={timePickerRef} className={classes.timeSubSection}>
            <TimePicker viewModel={timePickerViewModel!} />
          </div>
        </div>
      </div>
      <div
        ref={bookingDetailsRef}
        data-hook="booking-details-section"
        className={classes.bookingDetailsSection}
      >
        <SectionHeader title={bookingDetailsSectionHeaderText} />
        <BookingDetails viewModel={bookingDetailsViewModel!} />
      </div>
    </div>
  );
};

export default WidgetWrapper;
