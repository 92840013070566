import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Text } from 'wix-ui-tpa/Text';
import { Divider } from 'wix-ui-tpa/Divider';
import { Checkbox } from 'wix-ui-tpa/Checkbox';
import { MobileDrawer } from 'wix-ui-tpa/MobileDrawer';
import { CheckboxGroup } from 'wix-ui-tpa/CheckboxGroup';
import { Popover, TriggerAction } from 'wix-ui-tpa/Popover';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { ReactComponent as CaretDown } from 'wix-ui-tpa/dist/src/assets/icons/CaretDown.svg';

import { classes, st } from './Filters.st.css';
import { FiltersDataHooks } from './dataHooks.const';
import { useCalendarActions } from '../../../Hooks/useCalendarActions';
import {
  FilterOption,
  FilterTypes,
  FilterViewModel,
} from '../../../ViewModel/filterViewModel/filterViewModel';
import {
  WidgetComponents,
  WidgetElements,
} from '../../../../../utils/bi/consts';
import settingsParams from '../../../settingsParams';

type ContentProps = {
  toggleOption: Function;
  options: FilterOption[];
  note?: string;
};

type CTAProps = {
  id: FilterTypes;
  options: FilterOption[];
  label: string;
  open: boolean;
  onClick: () => void;
  ctaRef: React.MutableRefObject<any>;
};

type FilterProps = {
  filterViewModel: FilterViewModel;
};

const Filter: React.FC<FilterProps> = ({ filterViewModel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef(null) as React.MutableRefObject<any>;
  const ctaRef = useRef(null) as React.MutableRefObject<any>;
  const escPressedRef = useRef(null) as React.MutableRefObject<any>;
  const { isMobile, isRTL } = useEnvironment();
  const { onFilterChanged, onElementClicked } = useCalendarActions();
  const PopoverElement = Popover.Element!;
  const PopoverContent = Popover.Content!;
  const { options, id, label, note } = filterViewModel;

  useEffect(() => {
    if (isOpen) {
      requestAnimationFrame(() => popoverRef.current.focus());
    } else if (escPressedRef.current) {
      escPressedRef.current = false;
      ctaRef.current.focus();
    }
  }, [isOpen]);

  const setFilterOptionsVisibility = (isVisible: boolean) => {
    onElementClicked(WidgetComponents.FILTER, WidgetElements.FILTER_BUTTON, {
      filterType: id,
    });
    setIsOpen(isVisible);
  };

  const onEscPress = () => {
    escPressedRef.current = true;
    setFilterOptionsVisibility(false);
  };

  const onClick = () => {
    setFilterOptionsVisibility(!isOpen);
  };

  const toggleOption = (optionID: string) => {
    const option = _.find(options, ({ value }) => value === optionID);
    let newSelectedValues: string[];

    if (option?.selected) {
      newSelectedValues = options
        .filter(({ selected }) => selected)
        .filter(({ value }) => value !== optionID)
        .map(({ value }) => value);
    } else {
      newSelectedValues = options
        .filter(({ selected, value }) => selected || value === optionID)
        .map(({ value }) => value);
    }
    onElementClicked(WidgetComponents.FILTER, WidgetElements.CHECKBOX);
    onFilterChanged(id, newSelectedValues);
  };

  const cta = (
    <CTA
      id={id}
      options={options}
      label={label}
      open={isOpen}
      onClick={onClick}
      ctaRef={ctaRef}
    />
  );

  const content = (
    <Content toggleOption={toggleOption} options={options} note={note} />
  );

  return isMobile ? (
    <div className={classes.filterWrapper}>
      {cta}
      <div className={classes.mobileWrapper}>
        <MobileDrawer isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
          <div className={classes.mobileContentWrapper}>
            <Text className={classes.mobileContentLabel}>{label}</Text>
            {content}
          </div>
        </MobileDrawer>
      </div>
    </div>
  ) : (
    <>
      <Popover
        // @ts-expect-error
        ref={popoverRef}
        shown={isOpen}
        placement={isRTL ? 'bottom-end' : 'bottom-start'}
        dynamicWidth
        triggerAction={TriggerAction.click}
        showArrow={false}
        onClickOutside={() => setFilterOptionsVisibility(false)}
        onEscPress={() => onEscPress()}
        onTabOut={() => setFilterOptionsVisibility(false)}
        className={classes.popover}
        tabIndex={-1}
        role="region"
        aria-labelledby={id}
        data-hook={id}
      >
        <PopoverElement>
          <div className={classes.filterWrapper}>{cta}</div>
        </PopoverElement>
        <PopoverContent>
          <div className={classes.desktopContentWrapper}>{content}</div>
        </PopoverContent>
      </Popover>
    </>
  );
};

export type FiltersProps = {
  filterViewModels: FilterViewModel[];
};

const CTA: React.FC<CTAProps> = ({
  open,
  onClick,
  options,
  label,
  id,
  ctaRef,
}) => {
  const { t } = useTranslation();

  const getLabel = (): string => {
    const numberOfSelectedOptions = options.filter(({ selected }) => selected)
      .length;
    let value;

    if (numberOfSelectedOptions === 0) {
      value = t('filter.all-options.label');
    } else if (numberOfSelectedOptions > 1) {
      value = String(numberOfSelectedOptions);
    } else {
      value = options.filter(({ selected }) => selected)[0].label;
    }

    return t('filter.cta.label', { label, value });
  };

  return (
    <div data-hook={FiltersDataHooks.FILTER_CTA}>
      <TextButton
        id={id}
        ref={ctaRef}
        role="listbox"
        aria-haspopup="dialog"
        aria-expanded={open}
        data-hook={FiltersDataHooks.FILTER_CTA + id}
        className={st(classes.filterCTA, { open })}
        priority={TEXT_BUTTON_PRIORITY.secondary}
        suffixIcon={<CaretDown className={classes.filterCTASuffix} />}
        onClick={onClick}
        contentClassName={classes.filterCTAContent}
      >
        {getLabel()}
      </TextButton>
    </div>
  );
};

const Content: React.FC<ContentProps> = ({ toggleOption, options, note }) => {
  return (
    <>
      <CheckboxGroup className={classes.checkboxGroup}>
        {options.map(({ value, label, selected }) => {
          return (
            <Checkbox
              data-hook={FiltersDataHooks.OPTION}
              className={classes.item}
              onChange={() => toggleOption(value)}
              checked={selected}
              key={value}
              label={label}
              withFocusRing
            />
          );
        })}
      </CheckboxGroup>
      {note && (
        <>
          <Divider
            data-hook={FiltersDataHooks.NOTE_DIVIDER}
            className={classes.noteDivider}
          />
          <Text data-hook={FiltersDataHooks.NOTE}>{note}</Text>
        </>
      )}
    </>
  );
};

const Filters: React.FC<FiltersProps> = ({ filterViewModels }) => {
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  if (filterViewModels.length) {
    return (
      <>
        <Divider
          data-hook={FiltersDataHooks.FILTER_DIVIDER}
          className={classes.divider}
        />
        <div
          data-hook={FiltersDataHooks.FILTERS_WRAPPER}
          className={st(classes.root, {
            direction: isMobile ? 'column' : 'row',
            alignment: settings.get(settingsParams.textAlignment),
            isMobile,
          })}
          role="region"
          aria-label={t('filters.aria-label')}
        >
          <Text className={classes.filterBy} data-hook={FiltersDataHooks.LABEL}>
            {t('filters.filter-by.label')}
          </Text>
          {filterViewModels.map((filterViewModel) => (
            <Filter
              key={filterViewModel.id}
              filterViewModel={filterViewModel}
            />
          ))}
        </div>
      </>
    );
  }
  return null;
};

export default Filters;
